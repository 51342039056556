import { Constants } from '@adobe/aem-spa-page-model-manager';
import load from '@loadable/component';

import { ExperienceFragment } from '@/components/ExperienceFragment/ExperienceFragment';
import { defaultValues } from '@/utils/defaultValues';

const FeatureProviderSearch = load(() => import('@/features/ProviderSearch'));

const ProviderSearch = (props) => {
    const {
        noResultsFragment,
        searchServerErrorFragment,
        title,
        description,
        image,
        media,
        illustration,
        typeSpecialty,
        zipCode,
        zipCodeError,
        searchError,
        filterLabel,
        filterAriaLabel,
        resetAllLabel,
        resetAllAriaLabel,
        seeResultsLabel,
        seeResultsAriaLabel,
        hideMobileIllustration,
        initialChildren,
        resultsChildren,
        maps,
        patientTypeId,
        notAcceptingPatientsText,
        existingPatientText,
        noOnlineSchedulingText,
        availabilityLabel,
        enableTypeahead,
        typeAheadFixedItem,
        coveo,
    } = props;

    const noResults = noResultsFragment ? (
        <ExperienceFragment
            cqItems={noResultsFragment[Constants.ITEMS_PROP]}
            cqItemsOrder={noResultsFragment[Constants.ITEMS_ORDER_PROP]}
            {...noResultsFragment}
        />
    ) : undefined;
    const error = searchServerErrorFragment ? (
        <ExperienceFragment
            cqItems={searchServerErrorFragment[Constants.ITEMS_PROP]}
            cqItemsOrder={searchServerErrorFragment[Constants.ITEMS_ORDER_PROP]}
            {...searchServerErrorFragment}
        />
    ) : undefined;

    return (
        <FeatureProviderSearch
            {...props}
            coveo={coveo}
            enableTypeahead={enableTypeahead}
            typeAheadFixedItem={typeAheadFixedItem}
            patientTypeId={patientTypeId}
            notAcceptingPatientsText={notAcceptingPatientsText}
            existingPatientText={existingPatientText}
            noOnlineSchedulingText={noOnlineSchedulingText}
            availabilityLabel={availabilityLabel}
            noResultsComponent={noResults}
            errorComponent={error}
            initialChildren={initialChildren}
            resultsChildren={resultsChildren}
            title={title || 'Find a Location'}
            description={description}
            image={image}
            media={media}
            illustration={illustration}
            searchLabel={typeSpecialty || 'Search Type or Specialty'}
            zipLabel={zipCode || 'City or Zip'}
            zipErrorMessage={zipCodeError || 'Please enter a valid ZIP code'}
            searchErrorMessage={searchError || 'Please enter at least one search query'}
            hideMobileIllustration={hideMobileIllustration}
            filterLabel={filterLabel || 'Filter'}
            filterAriaLabel={filterAriaLabel}
            resetAllLabel={resetAllLabel || 'Reset'}
            resetAllAriaLabel={resetAllAriaLabel}
            seeResultsLabel={seeResultsLabel || 'See Results'}
            seeResultsAriaLabel={seeResultsAriaLabel}
            googleMaps={defaultValues(
                {
                    apiKey: 'AIzaSyDRdNprx4liInUfIU8gA6HpPL9p4tu8NYQ',
                    mapId: 'b936a6d7221f66ee',
                },
                maps,
            )}
        />
    );
};

export default ProviderSearch;
